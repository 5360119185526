import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import UnderlineHeading from '../components/underline';

function Superscript() {
  return <span className="align-text-top text-base">2</span>;
}

function SumDoodle() {
  return (
    <div style={{ columnCount: 2 }}>
      <div className="flex flex-col items-center justify-around">
        <Sum num1={1} num2={7} />
        <Sum num1={2} num2={7} />
        <Sum num1={3} num2={7} />
      </div>
      <div className="flex flex-col items-center justify-around">
        <Sum num1={4} num2={7} />
        <Sum num1={5} num2={7} />
        <Sum num1={6} num2={7} />
      </div>
    </div>
  );
}

function PythagorasDoodle() {
  return (
    <>
      <span className="nanum-pen text-contrast text-4xl mb-2">
        a<Superscript /> + b<Superscript /> = c<Superscript />
      </span>
      <StaticImage
        src="../images/pyth.png"
        width={150}
        layout="constrained"
        quality={95}
        placeholder="blurred"
        formats={['auto', 'webp', 'avif']}
        alt="One to One logo"
      />
    </>
  );
}

function CircleDoodle() {
  return (
    <>
      <span className="nanum-pen text-contrast text-4xl mb-2">c = 2 x π x r</span>
      <StaticImage
        src="../images/circ.png"
        width={100}
        layout="constrained"
        quality={95}
        placeholder="blurred"
        formats={['auto', 'webp', 'avif']}
        alt="One to One logo"
      />
    </>
  );
}

function SuccessDoodle() {
  return (
    <StaticImage
      src="../images/success.png"
      width={200}
      layout="constrained"
      quality={95}
      placeholder="blurred"
      formats={['auto', 'webp', 'avif']}
      alt="One to One logo"
    />
  );
}

function AboutSection({
  first,
  heading,
  text,
  reverse,
  i,
}: {
  first: boolean;
  heading: string;
  text: string[];
  reverse: boolean;
  i: number;
}) {
  return (
    <div className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center`}>
      <div className="md:w-2/3 px-4 flow max-w-prose mx-auto">
        <UnderlineHeading align="mx-auto md:mx-0">
          {first ? <h2 className="text-2xl font-bold">{heading}</h2> : <h3 className="text-xl font-bold">{heading}</h3>}
        </UnderlineHeading>
        {text.map(t => (
          <p key={t}>{t}</p>
        ))}
      </div>
      <div className="w-full md:w-1/3 flex flex-col items-center">
        {i === 0 && <SumDoodle />}
        {i === 1 && <PythagorasDoodle />}
        {i === 2 && <CircleDoodle />}
        {i === 3 && <SuccessDoodle />}
      </div>
    </div>
  );
}

function Sum({ num1, num2 }: Record<string, number>) {
  return (
    <span className="nanum-pen text-contrast text-4xl">
      {num1} x {num2} = {num1 * num2}
    </span>
  );
}

function About({
  data,
}: {
  data: {
    pagesJson: {
      aboutSections: {
        heading: string;
        text: string[];
      }[];
    };
  };
}) {
  const { aboutSections } = data.pagesJson;
  return (
    <Layout heading="About One to One" subheading="Bournemouth, Christchurch and Poole private tutor">
      <SEO title="About" />
      <div className="flow py-8">
        {aboutSections.map((s, i) => (
          <AboutSection key={s.heading} i={i} first={i === 0} reverse={i % 2 > 0} heading={s.heading} text={s.text} />
        ))}
      </div>
    </Layout>
  );
}

export default About;

export const query = graphql`
  {
    pagesJson(page: { eq: "about" }) {
      aboutSections {
        heading
        text
      }
    }
  }
`;
